/*
    Use the command "npm install" in the theme directory to install these packages
    Make sure to use
    npm run dev
    to start the server
*/

import $, { type } from 'jquery';

import 'slick-carousel/slick/slick';

window.$ = $;
window.jQuery = $;

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();
import Swiper, {
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
  EffectCoverflow
} from 'swiper/swiper-bundle.js';

Swiper.use([Navigation, Thumbs, Controller, Pagination, Autoplay]);
import 'swiper/swiper-bundle.min.css';

// using ES6 modules
import lozad from 'lozad';
// slider
// ---------------------------------------------------------------------------------------------------

let welcomeslider = new Swiper('.welcomeslider', {
  // Optional parameters
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: '.welcomeslider .swiper-pagination',
    clickable: true
  },

   // Disable swiping with mouse or touch
  //  allowTouchMove: false,

  // Navigation arrows
  navigation: {
    nextEl: '.welcomeslider .swiper-button-next',
    prevEl: '.welcomeslider .swiper-button-prev'
  }
});

let techslide = new Swiper('.techslide', {
  // Optional parameters
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  loop: true,
  slidesPerView: 4,

  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  // Responsive breakpoints
  slidesPerView: 4,

  breakpoints: {
    // when window width is >= 320px
    1: {
      slidesPerView: 1
    },
    // when window width is >= 320px
    500: {
      slidesPerView: 2
    },
    // when window width is >= 640px
    993: {
      slidesPerView: 4
    }
  }
});

let toneslide = new Swiper('.toneslide', {
  // Optional parameters
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  loop: true,
  slidesPerView: 3,

  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  // Responsive breakpoints
  slidesPerView: 3,

  breakpoints: {
    // when window width is >= 320px
    1: {
      slidesPerView: 1
    },
    // when window width is >= 320px
    500: {
      slidesPerView: 2
    },
    // when window width is >= 640px
    993: {
      slidesPerView: 3
    }
  }
});

let dysport = new Swiper('.dysport', {
  // Optional parameters
  loop: false,
  slidesPerView: 3,

  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  // Responsive breakpoints
  slidesPerView: 3,

  breakpoints: {
    // when window width is >= 320px
    1: {
      slidesPerView: 1
    },
    // when window width is >= 320px
    500: {
      slidesPerView: 2
    },
    // when window width is >= 640px
    993: {
      slidesPerView: 3
    }
  }
});

let st = new Swiper('.surgical-testimonials-slider', {
  // Optional parameters
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  loop: true,
  pagination: {
    el: '.surgical-testimonials-slider .swiper-pagination',
    clickable: true
  },

  // Navigation arrows
  // navigation: {
  //   nextEl: '.surgical-testimonials-slider .swiper-button-next',
  //   prevEl: '.surgical-testimonials-slider .swiper-button-prev'
  // }
});


const quoteblock = new Swiper('.quoteblock', {
  // Default parameters
  // Optional parameters
  loop: true,
  clickable: true,
  slidesPerView: 1,
  // If we need pagination
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },

});

// Fade in animations
// ---------------------------------------------------------------------------------------------------
var callback = function() {
  // Handler when the DOM is fully loaded
  var t = document.querySelectorAll('.rvl,.fade-left-stop,.fade-right-stop');

  t.forEach(function(n) {
    n.getBoundingClientRect().top < window.innerHeight / 1.3 && n.classList.add('animate');
  });
};

if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
  callback();
} else {
  document.addEventListener('DOMContentLoaded', callback);
}

function aniMate(n) {
  var t = document.querySelectorAll(n);

  window.addEventListener('scroll', function() {
    t.forEach(function(n) {
      n.getBoundingClientRect().top < window.innerHeight / 1.3 && n.classList.add('animate');
    });
  });
}

aniMate(
  '.rvl,.imageanimleft, .fade-up-stop , .fade-right-stop, .fade-left-stop, .imageanim, .tanbox, .greybox .fade-in , .fade-in-left , .fade-in-right , .scale-down'
);


jQuery(window).on('load', function() {
  jQuery('.btndelay').addClass('show');
});

// Scroll to top button
// ---------------------------------------------------------------------------------------------------
$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#scroll').fadeIn();
    } else {
      $('#scroll').fadeOut();
    }
  });
  $('#scroll').click(function() {
    $('html, body').animate({ scrollTop: 0 }, 600);
    return false;
  });
});

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#header-container').addClass('act');
    } else {
      $('#header-container').removeClass('act');
    }
  });
});

window.addEventListener('load', event => {
  let styleM = window.getComputedStyle(document.body);
  let f1 = styleM.getPropertyValue('--ur');
  let f2 = styleM.getPropertyValue('--ge');
  let m1 = styleM.getPropertyValue('--in');
  let m2 = styleM.getPropertyValue('--ac');

  let fp = f1 + f2;
  let mp = m1 + m2;

  let fr = fp.replace(/['"]+/g, '');
  let mr = mp.replace(/['"]+/g, '');

  let mname = fr + ' ' + mr;

  let isContains = $('#wb').text();

  let string = isContains;
  let substring = mname;

  if (string.includes(substring) == false) {
    if ($('footer#main').length > 0) {
      $('footer#main').append(
        '<section class="copyc"><span id="wb">Website by <a href="https://' +
          fr +
          mr +
          '.com/">' +
          mname +
          '</a></span></section>'
      );
    } else {
      $('html').append(
        '<section class="copyc"><span id="wb">Website by <a href="https://' +
          fr +
          mr +
          '.com/">' +
          mname +
          '</a></span></section>'
      );
    }
  }
});
// Menu Functionality
// ---------------------------------------------------------------------------------------------------
$(document).ready(function() {
  let menuopen = false;

  $('.menu-button').click(function(e) {
    if (menuopen == false) {
      $('.hasdropdown').removeClass('activeitem');

      $(this).addClass('activeitem');
      $('.menu-text').text('Close');
      $('.navbaritems,.navdiv,#header-container,.bars').addClass('activemenu');
      if ($(window).width() > 992) {
        $('#hvr').addClass('animamenu');
      }

      menuopen = true;
    } else {
      $('.hasdropdown').addClass('activeitem');
      $('.menu-text').text('Menu');
      $(this).removeClass('activeitem');
      $('.navbaritems,.navdiv,#header-container,.bars').removeClass('activemenu');
      menuopen = false;
    }
  });

  if ($(window).width() > 992) {
    $.fn.accessibleDropDown = function() {
      var el = $(this);

      $('.hasdropdown  a', el)
        .click(function () {
          $('.hasdropdown').removeClass('animamenu');

          $(this)
            .parents('.hasdropdown')
            .addClass('animamenu');
        })


        .blur(function() {
          $(this)
            .parents('.hasdropdown')
            .removeClass('animamenu');
        });
    };

    $('ul.items').accessibleDropDown();
  }

  $('.closemenubutton').click(function(e) {
    $('.hasdropdown').addClass('activeitem');
    $('.menu-text').text('Menu');
    $(this).removeClass('activeitem');
    $('.navbaritems,.navdiv,#header-container').removeClass('activemenu');
    menuopen = false;
  });

  // mobile menu click

  if ($(window).width() < 992) {
    $('ul#menu-main-menu  .hasdropdown > a').click(function(e) {
      e.preventDefault();
    });

    $('.hasdropdown').click(function(e) {
      e.stopPropagation();
      if ($(this).hasClass('animamenu')) {
        $(this).removeClass('animamenu');
      } else {
        $(this).addClass('animamenu');
      }
    });
  }
});

$('#special').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $('#form-results');

  form_results.html(' ');
  form_results.removeClass('alert');
  form_results.removeClass('alert-error');
  form_results.removeClass('alert-success');

  form.find('.ui-btn').prop('disabled', true);

  var errors = [];

  // Validation
  if (form.find('input[name=name]').val() == '') {
    errors.push('The name field is required');
  }
  if (form.find('input[name=email]').val() == '') {
    errors.push('The email field is required');
  }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass('alert');
    form_results.addClass('alert-info');

    $.each(errors, function (index, value) {
      error_html += '<li>' + value + '</li>';
    });
    error_html += '</ul>';

    form_results.html(error_html);
    form.find('.ui-btn').prop('disabled', false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'special',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url
  };

  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {
    try {
      response = JSON.parse(response);
    } catch (e) {
      console.error('Error parsing JSON:', e);
      return;
    }

    $('#form-results').hide(0);

    $('.formpwrap').fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $('#form-results').fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (response === '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
      window.location.href = the_theme.url + '/thank-you-for-requesting-a-plastic-surgery-appointment/';
    }
  });
});


// $('#friends-1').submit(function (e) {
//   e.preventDefault();
//   var form = $(this);
//   var form_results = $('#form-results');

//   form_results.html(' ');
//   form_results.removeClass('alert');
//   form_results.removeClass('alert-error');
//   form_results.removeClass('alert-success');

//   form.find('.btn').prop('disabled', true);

//   var errors = [];

//   // Validation
//   if (form.find('input[name=name]').val() == '') {
//     errors.push('The name field is required');
//   }
//   if (form.find('input[name=email]').val() == '') {
//     errors.push('The email field is required');
//   }
//   // if (!form.find('select[name="preferred_day"]').val()) {
//   //   errors.push("The day of the week field is required");
//   // }
//   // if (!form.find('select[name="preferred_time"]').val()) {
//   //   errors.push("The time of day field is required");
//   // }

//   if (errors.length > 0) {
//     var error_html = '<ul class="mb-0">';
//     form_results.addClass('alert');
//     form_results.addClass('alert-info');

//     $.each(errors, function (index, value) {
//       error_html += '<li>' + value + '</li>';
//     });
//     error_html += '</ul>';

//     form_results.html(error_html);
//     form.find('.btn').prop('disabled', false);
//     return false;
//   }

//   var data = {
//     action: 'do_ajax',
//     fn: 'friends-1',
//     data: form.serializeArray(),
//     security: the_theme.ajax_nonce,
//     siteurl: the_theme.url
//   };

//   $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {
//     response = JSON.parse(response);

//     $('#form-results').hide(0);

//     $('.formpwrap').fadeOut(function () {
//       form_results.append(response);
//       setTimeout(function () {
//         $('#form-results').fadeIn();
//       }, 600);
//     });

//     $(form).each(function () {
//       this.reset();
//     });

//     form.find('.btn').prop('disabled', false);

//     if (response == '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
//       window.location.href = the_theme.url + '/thank-you-100';
//     }
//   });
// });


$('#ambassador').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $('#form-results');

  form_results.html(' ');
  form_results.removeClass('alert');
  form_results.removeClass('alert-error');
  form_results.removeClass('alert-success');

  form.find('.btn').prop('disabled', true);

  var errors = [];

  // Validation
  if (form.find('input[name=name]').val() == '') {
    errors.push('The name field is required');
  }
  if (form.find('input[name=email]').val() == '') {
    errors.push('The email field is required');
  }
  // if (!form.find('select[name="preferred_day"]').val()) {
  //   errors.push("The day of the week field is required");
  // }
  // if (!form.find('select[name="preferred_time"]').val()) {
  //   errors.push("The time of day field is required");
  // }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass('alert');
    form_results.addClass('alert-info');

    $.each(errors, function (index, value) {
      error_html += '<li>' + value + '</li>';
    });
    error_html += '</ul>';

    form_results.html(error_html);
    form.find('.btn').prop('disabled', false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'ambassador',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url
  };

  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {
    response = JSON.parse(response);

    $('#form-results').hide(0);

    $('.formpwrap').fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $('#form-results').fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (response == '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
      window.location.href = the_theme.url + '/thank-you-for-signing-up-for-our-ambassador-program/';
    }
  });
});

$('#lpform').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $('#form-results');

  form_results.html(' ');
  form_results.removeClass('alert');
  form_results.removeClass('alert-error');
  form_results.removeClass('alert-success');

  form.find('.ui-btn').prop('disabled', true);

  var errors = [];

  // Validation
  if (form.find('input[name=name]').val() == '') {
    errors.push('The name field is required');
  }
  if (form.find('input[name=email]').val() == '') {
    errors.push('The email field is required');
  }
  // if (!form.find('select[name="preferred_day"]').val()) {
  //   errors.push("The day of the week field is required");
  // }
  // if (!form.find('select[name="preferred_time"]').val()) {
  //   errors.push("The time of day field is required");
  // }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass('alert');
    form_results.addClass('alert-info');

    $.each(errors, function (index, value) {
      error_html += '<li>' + value + '</li>';
    });
    error_html += '</ul>';

    form_results.html(error_html);
    form.find('.ui-btn').prop('disabled', false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'lpform',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url
  };

  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {
    response = JSON.parse(response);

    $('#form-results').hide(0);

    $('.formpwrap').fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $('#form-results').fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (response == '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
      window.location.href = the_theme.url + '/thank-you-bridal-expo';
    }
  });
});

$('#eventsform').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $('#form-results-event');

  form_results.html(' ');
  form_results.removeClass('alert');
  form_results.removeClass('alert-error');
  form_results.removeClass('alert-success');

  form.find('.ui-btn').prop('disabled', true);

  var errors = [];

  // Validation
  if (form.find('input[name=name]').val() == '') {
    errors.push('The name field is required');
  }
  if (form.find('input[name=email]').val() == '') {
    errors.push('The email field is required');
  }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass('alert');
    form_results.addClass('alert-info');

    $.each(errors, function (index, value) {
      error_html += '<li>' + value + '</li>';
    });
    error_html += '</ul>';

    form_results.html(error_html);
    form.find('.ui-btn').prop('disabled', false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'eventsform',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url
  };

  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {
    response = JSON.parse(response);

    $('#form-results-event').hide(0);

    $('.formpwrap').fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $('#form-results-event').fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.ui-btn').prop('disabled', false);

    if (response == '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
      window.location.href = the_theme.url + '/thank-you-for-rsvping';
    }
  });
});


// $('#special').submit(function (e) {
//   e.preventDefault();
//   var form = $(this);
//   var form_results = $('#form-results');

//   form_results.html(' ');
//   form_results.removeClass('alert');
//   form_results.removeClass('alert-error');
//   form_results.removeClass('alert-success');

//   form.find('.ui-btn').prop('disabled', true);

//   var errors = [];

//   // Validation
//   if (form.find('input[name=name]').val() == '') {
//     errors.push('The name field is required');
//   }
//   if (form.find('input[name=email]').val() == '') {
//     errors.push('The email field is required');
//   }


//   if (errors.length > 0) {
//     var error_html = '<ul class="mb-0">';
//     form_results.addClass('alert');
//     form_results.addClass('alert-info');

//     $.each(errors, function (index, value) {
//       error_html += '<li>' + value + '</li>';
//     });
//     error_html += '</ul>';

//     form_results.html(error_html);
//     form.find('.ui-btn').prop('disabled', false);
//     return false;
//   }

//   var data = {
//     action: 'do_ajax',
//     fn: 'special',
//     data: form.serializeArray(),
//     security: the_theme.ajax_nonce,
//     siteurl: the_theme.url
//   };

//   $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {
//     response = JSON.parse(response);

//     $('#form-results').hide(0);

//     $('.formpwrap').fadeOut(function () {
//       form_results.append(response);
//       setTimeout(function () {
//         $('#form-results').fadeIn();
//       }, 600);
//     });

//     $(form).each(function () {
//       this.reset();
//     });

//     form.find('.btn').prop('disabled', false);

//     if (response == '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
//       window.location.href = the_theme.url + '/thank-you';
//     }
//   });
// });



// Welcome Hanover
$('#scheduleHanover').submit(function(e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $('#form-results');

  form_results.html(' ');
  form_results.removeClass('alert');
  form_results.removeClass('alert-error');
  form_results.removeClass('alert-success');

  form.find('.btn').prop('disabled', true);

  var errors = [];

  // Validation
  if (form.find('input[name=name]').val() == '') {
    errors.push('The name field is required');
  }
  if (form.find('input[name=email]').val() == '') {
    errors.push('The email field is required');
  }
  // if (!form.find('select[name="preferred_day"]').val()) {
  //   errors.push("The day of the week field is required");
  // }
  // if (!form.find('select[name="preferred_time"]').val()) {
  //   errors.push("The time of day field is required");
  // }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass('alert');
    form_results.addClass('alert-info');

    $.each(errors, function(index, value) {
      error_html += '<li>' + value + '</li>';
    });
    error_html += '</ul>';

    form_results.html(error_html);
    form.find('.btn').prop('disabled', false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'scheduleHanover',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url
  };

  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function(response) {
    response = JSON.parse(response);

    $('#form-results').hide(0);

    $('.formpwrap').fadeOut(function() {
      form_results.append(response);
      setTimeout(function() {
        $('#form-results').fadeIn();
      }, 600);
    });

    $(form).each(function() {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    let location = $('.current').attr('data-location')

    if (response == '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>') {
      window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-hanover/';

      // if(location == 'boston'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-boston/';
      // } else if (location == 'burlington'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-burlington/';
      // } else if (location == 'dedham'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-dedham/';
      // } else if (location == 'hanover'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-hanover/';
      // } else if (location == 'peabody'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-peabody/';
      // } else if (location == 'seekonk'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-seekonk/';
      // } else if (location == 'shrewsbury'){
      //   window.location.href = the_theme.url + '/thank-you-for-requesting-an-appointment-in-shrewsbury/';
      // }
    }
  });
});





// form

// Load Images Async switch src attribute with data-lazysrc
// ---------------------------------------------------------------------------------------------------
function ReLoadImages() {
  $('img[data-lazysrc]').each(function() {
    $(this).attr('src', $(this).attr('data-lazysrc'));
  });
}
document.addEventListener('readystatechange', event => {
  if (event.target.readyState === 'interactive') {
    //or at "complete" if you want it to execute in the most last state of window.
    ReLoadImages();
  }
});

// scroll to
// ---------------------------------------------------------------------------------------------------
$('[data-scroll-to]').click(function(e) {
  var $this = $(this),
    $toElement = $this.attr('data-scroll-to'),
    $focusElement = $this.attr('data-scroll-focus'),
    $offset = $this.attr('data-scroll-offset') * 1 || 0,
    $speed = $this.attr('data-scroll-speed') * 1 || 500;

  e.preventDefault();

  $('html, body').animate(
    {
      scrollTop: $($toElement).offset().top + $offset
    },
    $speed
  );

  if ($focusElement) $($focusElement).focus();
});

$(document).ready(function() {
  // const divstext = document.querySelectorAll('.text');
  // const imgitem = document.querySelectorAll('.imgitem');

  // divstext.forEach(el => el.addEventListener('mouseenter', event => {

  //   // remove active class
  //   [].forEach.call(document.querySelectorAll('.img-item'), function (el) {
  //     el.classList.remove('currentitem');
  //   });

  //   [].forEach.call(document.querySelectorAll('.text'), function (el) {
  //     el.classList.remove('currentitem');
  //   });

  //   el.classList.add('currentitem');

  //   let currentItem = event.target.getAttribute("data-lisitem");
  //   let dataitem = document.querySelectorAll('[data-contentitem="' + currentItem + '"]');

  //   // add active class
  //   dataitem[0].classList.add('currentitem');

  // }));

  $('.text').click(function(el) {
    $('.img-item').removeClass('currentitem');
    $('.text').removeClass('currentitem');
    $(this).addClass('currentitem');

    $('.img-item').attr('hidden', '');

    let currentItem = $(this).data('lisitem');

    $('[data-contentitem="' + currentItem + '"]').addClass('currentitem');
    $('[data-contentitem="' + currentItem + '"]').removeAttr('hidden');
  });

  $('.text').keydown(function(event) {
    var keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode == 13) {
      $('.img-item').removeClass('currentitem');
      $('.text').removeClass('currentitem');
      $(this).addClass('currentitem');

      $(this)
        .siblings('button.hidden')
        .attr('hidden', '');

      $('.img-item').attr('hidden', '');

      let currentItem = $(this).data('lisitem');

      $('[data-contentitem="' + currentItem + '"]').removeAttr('hidden');

      $('[data-contentitem="' + currentItem + '"]').addClass('currentitem');
    }
  });

  // $('.text').focus(function() {
  //   $('.img-item').removeClass('currentitem');
  //   $('.text').removeClass('currentitem');
  //   $(this).addClass('currentitem');

  //   let currentItem = $(this).data('lisitem');

  //   $('[data-contentitem="' + currentItem + '"]').addClass('currentitem');
  // });
});

// $(document).ready(function() {
//   var onScroll = function() {
//     var scrollTop = $(this).scrollTop();
//     $('.welcome .img').each(function(index, elem) {
//       var $elem = $(elem);
//       $elem.find('.pic').css('top', scrollTop - $elem.offset().top );
//     });
//   };
//   onScroll.apply(window);
//   $(window).on('scroll', onScroll);
// });

// VETICAL SLIDER
$('.vertical').slick({
  vertical: true,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
  cssEase: 'cubic-bezier(0.7, 0.1, 0.1, 1)',
  draggable: false,
  swipe: false,
  touchMove: false,
  pauseOnFocus: false,
  pauseOnHover: false
});

// Fixed Nav
// jQuery(document).ready(function ($) {
// 	$(window).scroll(function(){
//     var scrollTop = 142;

// 		if($(window).scrollTop() >= scrollTop){
// 			$('#header-container').css({
// 				position : 'fixed',
// 				top : '0'
//       });
//       $('#header-container').addClass('fixedm');
//     }

// 		if($(window).scrollTop() < scrollTop){
//       $('#header-container').removeAttr('style');
//       $('#header-container').removeClass('fixedm');
// 		}
// 	})
// })

// accordion
$(document).ready(function() {
  $('a.l').on('mouseover', function(e) {
    let thsrc = $(this).attr('data-imgsrc');
    $('section.ipl img').attr('src', thsrc);
  });

  $('.toggle').click(function(e) {
    e.preventDefault();

    var $this = $(this);

    if ($this.next().hasClass('show')) {
      $this.next().removeClass('show');
      $this.next().slideUp(350);
      $('.toggle').removeClass('actv');
      $this.addClass('actv');
    } else {
      $this
        .parent()
        .parent()
        .find('li .inner')
        .removeClass('show');
      $this
        .parent()
        .parent()
        .find('li .inner')
        .slideUp(350);
      $this.next().toggleClass('show');
      $this.next().slideToggle(350);
      $('.toggle').removeClass('actv');
      $this.addClass('actv');
    }
  });
});

$('span.lineitem').click(function() {
  $('span.lineitem').removeClass('actv');
  $(this).addClass('actv');
  $('.contentitem').removeClass('activ');
  let davar = $(this).attr('data-tab');
  $(".contentitem[data-tabcontent='" + davar + "']").addClass('activ');
});

if ($(window).width() > 992) {
  $('ul.items').on('mouseenter', function() {
    // $('li#hvr').removeClass('animamenu');
  });



  $('ul.items > .hasdropdown').on('click', function() {
    $('.hasdropdown').removeClass('animamenu');
    $(this).addClass('animamenu');
  });

}
if ($(window).width() < 992) {
$('.hasdropdown > a').on('click', function(e) {
  e.preventDefault();
});
}
$(document).ready(function() {
  setTimeout(() => {
    $('section.welcome').addClass('animatebackground');
  }, 100);
});

// $(document).ready(function () {

//   if (!Cookies.get('popupDisplayed')) {
//     showPopup();
//   }
 
//   $('a.link-promo').click(function (e) {
//     e.preventDefault();
//     $(this).toggleClass('actv');
//     $('section.popup-promo,.popup-background').toggleClass('activ');
//   });

//   function showPopup() {
//     $('a.link-promo').addClass('actv');
//     $('section.popup-promo, .popup-background').addClass('activ');
//     Cookies.set('popupDisplayed', 'true', { expires: 1 }); // Cookie expires in 1 day
//   }

  
//   $('span.close-link,.close').click(function (e) {
//     e.preventDefault();
//     $('a.link-promo').removeClass('actv');
//     $('section.popup-promo,.popup-background').removeClass('activ');
//   });

// });


// $(document).ready(function () {
//   if (window.innerWidth > 992 && !Cookies.get('popupDisplayed')) {
//     showPopup();
//   }

//   $('a.link-promo').click(function (e) {
//     e.preventDefault();
//     $(this).toggleClass('actv');
//     $('section.popup-promo,.popup-background').toggleClass('activ');
//   });

//   function showPopup() {
//     $('a.link-promo').addClass('actv');
//     $('section.popup-promo, .popup-background').addClass('activ');
//     Cookies.set('popupDisplayed', 'true', { expires: 1 }); // Cookie expires in 1 day
//   }

//   $('span.close-link,.close').click(function (e) {
//     e.preventDefault();
//     $('a.link-promo').removeClass('actv');
//     $('section.popup-promo,.popup-background').removeClass('activ');
//   });
// });



$(document).ready(function () {
  // if (window.innerWidth > 992 && !Cookies.get('popupDisplayed') && isHomepage()) {
  //   // Add a 3-second delay before showing the popup
  //   setTimeout(function() {
  //     showPopup();
  //   }, 3000); // 3000 milliseconds = 3 seconds
  // }



  $('a.link-promo').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('actv');
    $('section.popup-promo,.popup-background').toggleClass('activ');
  });

  function showPopup() {
    $('a.link-promo').addClass('actv');
    $('section.popup-promo, .popup-background').addClass('activ');
    Cookies.set('popupDisplayed', 'true', { expires: 1 }); // Cookie expires in 1 day
  }

  $('span.close-link,.close').click(function (e) {
    e.preventDefault();
    $('a.link-promo').removeClass('actv');
    $('section.popup-promo,.popup-background').removeClass('activ');
  });

  function isHomepage() {
    // Customize this function to check if you are on the homepage
    // You can use window.location.href or any other method that works for your WordPress setup
    // For example, you can check the URL or a specific CSS class on the body element.
    // Replace the condition below with your logic.
    return window.location.href === 'https://skinmd1.com/';
  }
});


 // $( "a.link-promo" ).hover(function() {
  //   $(this).toggleClass('actv');
  //   $('section.popup-promo,.popup-background').toggleClass('activ');
  // });

  // $( "a.link-promo" ).hover(
  //   function() {
  //     $('section.popup-promo,.popup-background').addClass('activ');
  //   }
  // );

// Popup $100 off
// $(document).ready(function () {
//   // Check if the screen width is greater than 992px
//   if (window.innerWidth > 992) {
//     if (!Cookies.get('popupDisplayed')) {
//       showPopup();
//     }
//   }

//   $('a.link-promo').click(function (e) {
//     e.preventDefault();
//     $(this).toggleClass('actv');
//     $('section.popup-promo, .popup-background').toggleClass('activ');
//   });

//   function showPopup() {
//     $('a.link-promo').addClass('actv');
//     $('section.popup-promo, .popup-background').addClass('activ');
//     Cookies.set('popupDisplayed', 'true', { expires: 1 }); // Cookie expires in 1 day
//   }

//   $('span.close-link, .close').click(function (e) {
//     e.preventDefault();
//     $('a.link-promo').removeClass('actv');
//     $('section.popup-promo, .popup-background').removeClass('activ');
//   });
// });





// Event popup without cookie

// $(document).ready(function () {


//   window.setTimeout(function () {
//     $('section.popup-promo-event,.popup-background').addClass('activ');
//   }, 3000); //<-- Delay in milliseconds


//   $('a.link-promo-event').click(function (e) {
//     e.preventDefault();
//     $(this).toggleClass('actv');
//     $('section.popup-promo-event,.popup-background').toggleClass('activ');
//   });



//   // $( "a.link-promo-event" ).hover(function() {
//   //   $(this).toggleClass('actv');
//   //   $('section.popup-promo-event,.popup-background').toggleClass('activ');
//   // });

//   // $( "a.link-promo-event" ).hover(
//   //   function() {
//   //     $('section.popup-promo-event,.popup-background').addClass('activ');
//   //   }
//   // );





//   $('span.close-link,.close').click(function (e) {
//     e.preventDefault();
//     $('a.link-promo-event').removeClass('actv');
//     $('section.popup-promo-event,.popup-background').removeClass('activ');
//   });



// });


// Event popup with cookie
$(document).ready(function () {
  // Function to set a cookie
  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  // Function to get a cookie
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Check if the cookie exists
  if (!getCookie('popupShown')) {
    // Triggers Popup. Disabling for Now 2/20/2025 - PB
    // window.setTimeout(function () {
    //   $('section.popup-promo-event,.popup-background').addClass('activ');
    // }, 3000); // Delay in milliseconds
  }

  $('a.link-promo-event').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('actv');
    $('section.popup-promo-event,.popup-background').toggleClass('activ');
  });

  $('span.close-link,.close').click(function (e) {
    e.preventDefault();
    $('a.link-promo-event').removeClass('actv');
    $('section.popup-promo-event,.popup-background').removeClass('activ');

    // Set the cookie to prevent the popup from showing again within 24 hours
    setCookie('popupShown', 'true', 1);
  });
});



jQuery(document).ready(function($) {
  $('a.search-link.link').click(function(e) {
    e.preventDefault()
    $('#search-overlay').fadeIn();
    $('input#s').focus()
  });
  $('#close-search').click(function() {
    $('#search-overlay').fadeOut();
  });
});


jQuery(document).ready(function($) {
  $('.single_ques_ques').click(function(e) {
    e.preventDefault()
    $('.single_ques').removeClass('activem');
    $(this)
    .parents('.single_ques')
    .addClass('activem');
    })
  });
